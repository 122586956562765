import setHtml from './setHtml';
import Tooltip from '@/blocks/Tooltip';

const useComma = ( legal, index ) => {
  // If there is more than one legal annotation, but it is not the last one, add a comma
  if ( legal?.length > 1 && index < legal?.length - 1 ) {
    return <sup>,</sup>;
  }
  return '';
};

/**
 * @function appendLegal
 * @param {copy} content that is attached to legal claim
 * @param {hashes} array of legal hashes to attach to the copy as annotations
 * @param {tooltip} tooltip hash to attach to the copy
 */
export const appendLegal = ( copy, hashes, tooltip ) => {
  const legal = hashes?.filter( ( hash ) => hash && hash !== '__none__' );
  const showLegal = legal?.length > 0;
  const showTooltip = tooltip && tooltip !== '__none__';

  return (
    <>
      <span { ...setHtml( copy ) } />&#8288;
      { showLegal && <span style={ { whiteSpace: 'nowrap' } }>{ legal.map( ( hash, index ) => <><span key={ hash } data-legal={ hash } />{ useComma( legal, index ) }</> ) }</span> }
      { showTooltip && <Tooltip disclosure={ tooltip } /> }
    </>
  );
};

/**
 * @function injectLegalAndTooltips
 * @description Given a string, return a single React node with legal and tooltips injected
 * Example input: "No throttling or data caps {data-caps} plus premium eero router included[eero]"
 */
export const injectLegalAndTooltips = ( copy ) => {
  const legalElementRegex = /\[.*?\]/g; // in the example input above this regex would match "[eero]"
  const tooltipElementRegex = /\{.*?\}/g; // this regex would match "{data-caps}"
  const containsLegal = legalElementRegex.test( copy );
  const containsTooltips = tooltipElementRegex.test( copy );

  if ( containsLegal ) {
    copy = copy.replaceAll( legalElementRegex, ( match ) => {
      const legalHash = match.substring( 1, match.length - 1 );
      return `<span data-legal="${ legalHash }"></span>`;
    } );
  }

  if ( ! containsTooltips ) return <span { ...setHtml( copy ) } />;

  const tooltipCodes = copy.match( tooltipElementRegex );
  const split = copy.split( tooltipElementRegex );
  const nodes = [];

  split.forEach( ( subcopy, i ) => {
    const code = tooltipCodes[ i ];
    nodes.push( <span { ...setHtml( subcopy ) } key={ `copy-${ i }` } /> );
    if ( code ) {
      const tooltipHash = code.substring( 1, code.length - 1 );
      nodes.push( <Tooltip disclosure={ tooltipHash } key={ `tooltip-${ i }` } width="14px" height="14px"/> );
    }
  } );

  return (
    <>
      { nodes }
    </>
  );
};

export default appendLegal;
