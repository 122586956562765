import sanitizeHtml from 'sanitize-html';

const setHtml = ( html = '' ) => ( {
  dangerouslySetInnerHTML: {
    __html: sanitizeHtml( html, {
      allowedAttributes: {
        '*': [ 'class', 'data-legal', 'disclosure', 'href', 'className' ]
      }
    } )
  }
} );

export default setHtml;
